import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Breadcrumb, BreadcrumbItem } from "../components/Breadcrumb";
import PageTitle from "../components/PageTitle";
import PageContainer from "../components/PageContainer";
import {
    Container,
    ContentsContainer,
    TitleWrapper,
    Title,
    InfoContainer,
    InfoWrapper,
    InfoBox,
    Info,
    InfoTitle,
    InfoContents,
    VideoWrapper,
    Video,
    ImageContainer,
    StyledImage,
} from "./historyDetailStyles";

export default ({ data }) => {
    const film = data.historyJson;
    const poster = data.poster.childImageSharp.fluid;

    const {
        id,
        number,
        superscription,
        title,
        breadCrumb,
        videoUrl,
        listOfFilms,
    } = film;

    const link = `/history/${id}`;
    return (
        <Layout>
            <SEO title={title} />
            <PageContainer>
                <Breadcrumb>
                    <BreadcrumbItem Link="/history" Text="History" />
                    <BreadcrumbItem Link={link} Text={breadCrumb} />
                </Breadcrumb>
                <PageTitle>
                    The {number}
                    <sup>{superscription}</sup> {title}
                </PageTitle>
                <Container>
                    <VideoWrapper>
                        <Video url={videoUrl} />
                    </VideoWrapper>
                    <ContentsContainer>
                        <TitleWrapper>
                            <Title>{title} - Film list</Title>
                        </TitleWrapper>
                        <InfoContainer>
                            <ImageContainer>
                                <StyledImage fluid={poster} />
                            </ImageContainer>
                            <InfoWrapper>
                                {listOfFilms.map(item => {
                                    const filmTitles = item.split("/");
                                    return (
                                        <InfoBox key={filmTitles[1]}>
                                            <Info>
                                                {filmTitles[2] ? (
                                                    <InfoTitle>
                                                        {filmTitles[2]}
                                                    </InfoTitle>
                                                ) : null}
                                                <InfoTitle>
                                                    {filmTitles[1]}
                                                </InfoTitle>
                                                <InfoContents>
                                                    {filmTitles[0]}
                                                </InfoContents>
                                            </Info>
                                        </InfoBox>
                                    );
                                })}
                            </InfoWrapper>
                        </InfoContainer>
                    </ContentsContainer>
                </Container>
            </PageContainer>
        </Layout>
    );
};

export const pageQuery = graphql`
    fragment KoffiaHistoryPosterImg on File {
        childImageSharp {
            fluid(maxWidth: 800) {
                ...GatsbyImageSharpFluid
            }
        }
    }

    query($id: String!, $posterFilePath: String!) {
        historyJson(id: { eq: $id }) {
            id
            number
            superscription
            title
            breadCrumb
            videoUrl
            listOfFilms
        }
        poster: file(relativePath: { eq: $posterFilePath }) {
            ...KoffiaHistoryPosterImg
        }
    }
`;
